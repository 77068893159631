export const iconKeyImageMapping = {
  plunger: require('@/assets/svg/plunger.svg'),
  flood: require('@/assets/svg/flood.svg'),
  edit: require('@/assets/svg/edit.svg'),
  toilet: require('@/assets/svg/toilet.svg'),
  shower: require('@/assets/svg/shower.svg'),
  sink: require('@/assets/svg/sink.svg'),
  bath: require('@/assets/svg/bath.svg'),
  kitchen: require('@/assets/svg/kitchen.svg'),
  urinal: require('@/assets/svg/urinal.svg'),
  washmachine: require('@/assets/svg/washmachine.svg'),
  dishwasher: require('@/assets/svg/dishwasher.svg'),
  fountain: require('@/assets/svg/fountain.svg'),
  faucet: require('@/assets/svg/faucet.svg')
}

export const problemValueIconKeyMapping: { [key: string]: keyof typeof iconKeyImageMapping } = {
  1: 'toilet',
  2: 'shower',
  3: 'sink',
  4: 'bath',
  5: 'kitchen',
  6: 'urinal',
  17: 'washmachine',
  38: 'dishwasher',
  43: 'fountain',
  9999: 'faucet'
}

export function getIconByKey(name: string): unknown {
  if (name in iconKeyImageMapping) {
    return iconKeyImageMapping[name as keyof typeof iconKeyImageMapping]
  }
  return iconKeyImageMapping.plunger
}

export function getIconKeyByProblemValue(problemValue: string) {
  if (problemValue in problemValueIconKeyMapping) {
    return problemValueIconKeyMapping[problemValue]
  }
}

export function getIconByProblemValue(problemValue: string) {
  return getIconByKey(getIconKeyByProblemValue(problemValue) ?? '')
}
