
























import Vue from 'vue'
import Component from 'vue-class-component'

import { getIconByKey, iconKeyImageMapping } from '@/utils/icons'

const SelectProps = Vue.extend({
  props: {
    options: Array,
    multiple: Boolean,
    value: Array
  }
})

export type SelectItem = {
  value: unknown;
  name: string;
  icon: unknown;
  label?: string;
}

@Component
export default class Select extends SelectProps {
  toggleSelect(value: never) {
    let result = []
    if (!(this.multiple)) {
      result = [value]
    } else if (this.value.indexOf(value) > -1) {
      result = [...this.value]
      result.splice(result.indexOf(value), 1)
    } else {
      result = [...this.value]
      result.push(value)
    }
    this.$emit('input', result)
  }

  isSelected(value: never) {
    return this.value.indexOf(value) > -1
  }

  getIcon(icon: keyof typeof iconKeyImageMapping) {
    return getIconByKey(icon)
  }
}
