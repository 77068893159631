import { Commit } from 'vuex'

import { State as RootState } from './index'

import ApiClient from '@/api'
import replacePlaceholders from '@/utils/replacePlaceholders'

const paymentMethodToPlanType = {
  cash: 'PrijsContant',
  safePlan: 'PrijsSafePlan',
  allIn: 'PrijsAllIn'
}

export interface State {
  priceText: string | null;
  priceTextFailure: boolean | null;
}

export interface Getters {
  getPaymentMethod?: keyof typeof paymentMethodToPlanType;
  getSurcharge?: number;
}

export default {
  namespaced: true,
  state: {
    priceText: null,
    priceTextFailure: null
  },
  mutations: {
    updatePriceText(state: State, value: State['priceText']) {
      state.priceText = value
    },
    updatePriceTextFailure(state: State, value: State['priceTextFailure']) {
      state.priceTextFailure = value
    }
  },
  getters: {
    getPaymentMethod: (state: State, getters: Getters, rootState: RootState) => {
      const appointmentPaymentMethod = rootState.appointment?.customerAppointment?.paymentMethod
      if (!appointmentPaymentMethod) {
        return rootState.appointmentStatus.customerAppointmentStatus?.paymentMethod
      }
      return appointmentPaymentMethod
    },
    getSurcharge: (state: State, getters: Getters, rootState: RootState) => {
      const proposalSurcharge = rootState.proposals.selectedProposal?.surcharge
      if (!proposalSurcharge) {
        return rootState.appointmentStatus.customerAppointmentStatus?.surcharge
      }
      return proposalSurcharge
    }
  },
  actions: {
    fetchAppointmentPrice({ commit, rootState, getters }: { commit: Commit; rootState: RootState; getters: Getters }) {
      const paymentMethod = getters.getPaymentMethod
      commit('updatePriceTextFailure', null)
      commit('updatePriceText', null)

      if (paymentMethod && Object.prototype.hasOwnProperty.call(paymentMethodToPlanType, paymentMethod)) {
        let priceInformationText = paymentMethodToPlanType[paymentMethod]
        if (priceInformationText === 'PrijsContant') {
          const surcharge = getters.getSurcharge
          if (surcharge === 50) {
            priceInformationText += 'Surcharge50'
          } else if (surcharge === 100) {
            priceInformationText += 'Surcharge100'
          }
        }

        ApiClient.getProblemPriceInformation(priceInformationText).then((data) => {
          commit('updatePriceTextFailure', false)
          commit('updatePriceText',
            replacePlaceholders(data.value, {
              NaamWBV: rootState.appointment?.customerAppointment?.addressDetail.corporationName ?? ''
            }))
        }).catch(() => {
          commit('updatePriceTextFailure', true)
        })
      } else {
        commit('updatePriceTextFailure', true)
      }
    }
  }
}
