






























import Vue from 'vue'
import Component from 'vue-class-component'
import { debounce as Debounce } from 'decko'

import extractErrorMessage from '@/utils/errorMessages'
import HelpComponent from '@/components/generic/HelpComponent.vue'

const props = {
  value: String,
  placeholder: String,
  v: Object,
  hint: String,
  uppercase: Boolean,
  updateOnKeyup: Boolean
}

const TextInputProps = Vue.extend({ props })

@Component({
  components: { HelpComponent },
  props
})
export default class TextInput extends TextInputProps {
  onBlur(event: Event) {
    const target = event.target as HTMLInputElement
    this.$emit('input', target?.value ?? null)
    if (this.v) {
      this.v.$touch()
      const input = this.$refs.inputField as HTMLInputElement
      if (this.v.$error) {
        input.setCustomValidity(extractErrorMessage(this.v))
      } else {
        input.setCustomValidity('')
      }
    }
  }

  @Debounce(500)
  onKeyup(event: Event) {
    if (this.updateOnKeyup) {
      const target = event.target as HTMLInputElement
      this.$emit('input', target?.value ?? null)
    }
  }

  extractErrorMessage(v: object): string {
    return extractErrorMessage(v)
  }

  focus() {
    const inputField = this.$refs.inputField as HTMLInputElement
    inputField.focus()
  }
}
