import { Commit, Dispatch } from 'vuex'

import { State as RootState } from '@/store/index'
import { Address } from '@/api/calls/address'
import ApiClient from '@/api'
import { AddressContract } from '@/api/types'

export interface State {
  houseNumber: string;
  postCode: string;
  addition: string;
  resolvedAddress: Address | null;
  resolvedAddressFailure: boolean;
  resolvedAdditions: string[];
  contractData: AddressContract | null;
}

export default {
  namespaced: true,
  state: {
    houseNumber: null,
    postCode: null,
    addition: null,
    resolvedAddress: null,
    resolvedAddressFailure: false,
    resolvedAdditions: [],
    contractData: null
  },
  mutations: {
    updatePostcode(state: State, value: string) {
      state.postCode = value.toUpperCase().replace(/\s/g, '')
    },
    updateHousenumber(state: State, value: string) {
      state.houseNumber = value
    },
    updateAddition(state: State, value: string) {
      state.addition = value
    },
    updateResolvedAddress(state: State, value: Address | null) {
      state.resolvedAddress = value
    },
    updateResolvedAddressFailure(state: State, value: boolean) {
      state.resolvedAddressFailure = value
    },
    updateResolvedAdditions(state: State, value: []) {
      state.resolvedAdditions = value
    },
    updateContractData(state: State, value: AddressContract) {
      state.contractData = value
    }
  },
  actions: {
    unsetResolvedAddress({ commit }: { commit: Commit }) {
      commit('updateResolvedAddress', null)
      commit('updateResolvedAddressFailure', false)
    },
    fetchResolvedAddress({ commit, state }: { commit: Commit; state: State }) {
      return ApiClient.getAddressInformation(state.postCode, state.houseNumber, state.addition).then(response => {
        commit('updateResolvedAddress', response)
        commit('updateResolvedAddressFailure', false)
      }).catch(() => {
        commit('updateResolvedAddress', null)
        commit('updateResolvedAddressFailure', true)
      })
    },
    fetchResolvedAdditions({ commit, state }: { commit: Commit; state: State }) {
      return ApiClient.getAddressAdditions(state.postCode, state.houseNumber).then(response => {
        commit('updateResolvedAdditions', response)
      })
    },
    restoreFromAppointmentStatus({ commit, dispatch, rootState }: { commit: Commit; dispatch: Dispatch; rootState: RootState }) {
      if (rootState.appointmentStatus.customerAppointment) {
        const address = rootState.appointmentStatus.customerAppointment?.address
        if (address) {
          commit('updatePostcode', address.postalCode.toString())
          commit('updateHousenumber', address.number.toString())
          commit('updateAddition', address.addition)
          dispatch('fetchResolvedAddress')
        }
      }
    },
    fetchContractInformation({ commit, state }: { commit: Commit; state: State }) {
      return ApiClient.getContractInformation(state.postCode, state.houseNumber).then(response => {
        commit('updateContractData', response)
      })
    }
  },
  modules: {}
}
