











































































import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Watch } from 'vue-property-decorator'

import { getIconByKey, getIconByProblemValue } from '@/utils/icons'
import { AppointmentProposal, CustomerAppointmentResponse, CustomerAppointmentStatusResponse, ProblemAreaResponse } from '@/api/types'
import { SelectItem } from '@/components/generic/Select.vue'
import { displayDateWithoutYear, displayTime } from '@/utils'
import { ERROR_TOAST } from '@/utils/toasts'
import Loading from '@/components/generic/Loading.vue'

const appointmentState = namespace('appointment')
const appointmentStatusState = namespace('appointmentStatus')
const problemAreasState = namespace('problemAreas')
const proposalsNamespace = namespace('proposals')
const contactDetailsNamespace = namespace('contactDetails')
const priceNamespace = namespace('price')

const props = {
  allowEditing: Boolean,
  allowEditingProblemType: Boolean
}
const AppointmentDetailProps = Vue.extend({ props })

@Component({
  name: 'AppointmentDetail',
  components: {
    Loading
  },
  props
})
export default class AppointmentDetail extends AppointmentDetailProps {
  @appointmentState.State(state => state.confirmCustomerAppointmentFailure) confirmCustomerAppointmentFailure!: boolean | null
  @appointmentState.State(state => state.customerAppointment) customerAppointment!: CustomerAppointmentResponse | null

  @appointmentStatusState.State(state => state.customerAppointmentStatus) customerAppointmentStatus!: CustomerAppointmentStatusResponse | null

  @problemAreasState.State(state => state.selectedProblemAreas) selectedProblemAreas!: [ProblemAreaResponse]
  @problemAreasState.State(state => state.remarks) problemAreasRemarks!: string | null

  @proposalsNamespace.State(state => state.selectedProposal) selectedProposal!: AppointmentProposal | null

  @contactDetailsNamespace.State(state => state.name) name!: string | null
  @contactDetailsNamespace.State(state => state.email) email!: string | null
  @contactDetailsNamespace.State(state => state.phoneNumber) phoneNumber!: string | null
  @contactDetailsNamespace.State(state => state.remarks) remarks!: string | null
  @contactDetailsNamespace.State(state => state.usedDisintegrant) usedDisintegrant!: boolean | null
  @contactDetailsNamespace.State(state => state.statusUpdatesSms) statusUpdatesSms!: boolean | null

  @priceNamespace.State(state => state.priceText) priceText!: string | null
  @priceNamespace.State(state => state.priceTextFailure) priceTextFailure!: boolean | null

  mounted() {
    if (this.selectedProposal) {
      this.$store.dispatch('price/fetchAppointmentPrice')
    }
  }

  get selectedProblemAreasList(): SelectItem[] {
    return this.selectedProblemAreas.map(item => {
      return {
        value: item.value,
        name: item.name,
        icon: getIconByProblemValue(item.value)
      }
    })
  }

  get selectedDateTime(): { date?: string; from?: string; till?: string; surcharge?: number } | null {
    if (this.selectedProposal) {
      return this.selectedProposal
    }

    if (this.customerAppointmentStatus?.from) {
      return {
        ...this.customerAppointmentStatus,
        date: this.customerAppointmentStatus?.from
      }
    }

    return null
  }

  @Watch('priceTextFailure')
  onPriceTextFailureChange(value: boolean | null) {
    if (value === true) {
      this.$bvToast.toast('Prijsinformatie kon niet worden opgehaald, probeer het later opnieuw', ERROR_TOAST)
    }
  }

  getIconByKey(key: string) {
    return getIconByKey(key)
  }

  displayDate(date: string) {
    return displayDateWithoutYear(date)
  }

  displayTime(date: string) {
    return displayTime(date)
  }

  changeToStep(value: number) {
    if (this.$store.state.appointment) {
      this.$store.commit('wizard/updateDisplayProgressBar', false)
    }
    this.$store.commit('wizard/updateCurrentStep', value)
    // If we aren't on the wizard go to it.
    if (this.$router.currentRoute.name !== 'wizard') {
      this.$router.replace('/')
    }
  }
}
