import { Commit } from 'vuex'

import ApiClient from '@/api'
import { CustomerAppointmentResponse, CustomerAppointmentStatusResponse, DilapidatedReason } from '@/api/types'
import { Getters as RootGetters, State as RootState } from '@/store/index'
import replacePlaceholders from '@/utils/replacePlaceholders'
import { displayDate, displayTime } from '@/utils'

const StatusMapping: { [key: string]: string } = {
  dilapidated: 'StatusVervallen',
  notAtHome: 'StatusNietThuis',
  onTheWay: 'StatusOnderweg',
  open: 'StatusOpen',
  planned: 'StatusGepland',
  started: 'StatusGestart'
}

export interface State {
  customerAppointmentStatus: CustomerAppointmentStatusResponse | null;
  customerAppointmentStatusFailure: boolean | null;
  customerAppointment: CustomerAppointmentResponse | null;
  customerAppointmentFailure: boolean | null;
  rawAppointmentText: string | null;
  dilapidatedReasons: DilapidatedReason[];
  rawCancellationSuccessText: string | null;
}

export interface Getters {
  statusText: string | null;
}

export default {
  namespaced: true,
  state: {
    customerAppointmentStatus: null,
    customerAppointmentStatusFailure: null,
    customerAppointment: null,
    customerAppointmentFailure: null,
    rawAppointmentText: null,
    dilapidatedReasons: [],
    rawCancellationSuccessText: null
  },
  mutations: {
    updateCustomerAppointmentStatus(state: State, value: CustomerAppointmentStatusResponse | null) {
      state.customerAppointmentStatus = value
    },
    updateCustomerAppointmentStatusFailure(state: State, value: boolean) {
      state.customerAppointmentStatusFailure = value
    },
    updateCustomerAppointment(state: State, value: CustomerAppointmentResponse | null) {
      state.customerAppointment = value
    },
    updateCustomerAppointmentFailure(state: State, value: boolean) {
      state.customerAppointmentFailure = value
    },
    updateRawAppointmentText(state: State, value: string) {
      state.rawAppointmentText = value
    },
    updateDilapidatedReasons(state: State, value: DilapidatedReason[]) {
      state.dilapidatedReasons = value
    },
    updateRawCancellationSuccessText(state: State, value: string) {
      state.rawCancellationSuccessText = value
    }
  },
  getters: {
    statusText: (state: State, getters: Getters, rootState: RootState, rootGetters: RootGetters) => {
      if (!state.rawAppointmentText) {
        return null
      }

      const from = state.customerAppointmentStatus?.from ?? ''
      const till = state.customerAppointmentStatus?.till ?? ''

      return replacePlaceholders(state.rawAppointmentText, {
        TelefoonNummerVestiging: rootGetters.getPhoneNumber,
        TelefoonNummerVestigingPlanning: rootGetters.getGeneralPhoneNumber,
        Datum: displayDate(from),
        TimeFrom: displayTime(from),
        TimeTill: displayTime(till)
      })
    },
    cancellationSuccessText: (state: State, getters: Getters, rootState: RootState, rootGetters: RootGetters) => {
      if (!state.rawCancellationSuccessText) {
        return false
      }

      return replacePlaceholders(state.rawCancellationSuccessText, {
        TelefoonNummerVestiging: rootGetters.getPhoneNumber
      })
    }
  },
  actions: {
    getCustomerAppointmentStatus({ commit, rootState }: { commit: Commit; rootState: RootState }) {
      commit('updateCustomerAppointmentStatus', null)
      commit('updateCustomerAppointmentStatusFailure', null)

      const address = rootState.address

      if (address.postCode && address.houseNumber) {
        ApiClient.getCustomerAppointmentStatus(address.postCode, address.houseNumber, address.addition).then(response => {
          commit('updateCustomerAppointmentStatus', response)
          // Dispatch request for correct status text
          ApiClient.getResource(StatusMapping[response.orderState]).then(response => {
            commit('updateRawAppointmentText', response.value)
          })

          commit('updateCustomerAppointmentStatusFailure', false)
        }).catch(() => {
          commit('updateCustomerAppointmentStatus', null)
          commit('updateCustomerAppointmentStatusFailure', true)
        })
      } else {
        commit('updateCustomerAppointmentStatus', null)
        commit('updateCustomerAppointmentStatusFailure', true)
      }
    },
    getCustomerAppointment({ commit, state, rootState }: { commit: Commit; state: State; rootState: RootState }) {
      commit('updateCustomerAppointment', null)
      commit('updateCustomerAppointmentFailure', null)

      const customerAppointmentId = state.customerAppointmentStatus?.customerAppointmentId ?? null
      const address = rootState.address

      if (customerAppointmentId && address.postCode && address.houseNumber) {
        ApiClient.getCustomerAppointment(customerAppointmentId, address.postCode, address.houseNumber, address.addition).then(response => {
          commit('updateCustomerAppointment', response)
          commit('updateCustomerAppointmentFailure', false)
        }).catch(() => {
          commit('updateCustomerAppointment', null)
          commit('updateCustomerAppointmentFailure', true)
        })
      } else {
        commit('updateCustomerAppointment', null)
        commit('updateCustomerAppointmentFailure', true)
      }
    },
    fetchDilapidatedReasons({ commit }: { commit: Commit }) {
      ApiClient.getDilapidatedReasons().then(response => {
        commit('updateDilapidatedReasons', response)
      })
    },
    fetchCancellationText({ commit }: {commit: Commit}) {
      ApiClient.getResource('InfoOpdrachtAnnulerenSucces').then(resource => {
        commit('updateRawCancellationSuccessText', resource.value)
      })
    }
  }
}
