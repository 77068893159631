import { Base } from '@/api/base'
import { AppointmentProposal, CustomerAppointmentResponse, CustomerAppointmentStatusResponse } from '@/api/types'

export const APPOINTMENT_FAILURE_API_ERROR = 'API_ERROR'

export class CustomerAppointment extends Base {
  createCustomerAppointment(postCode: string, houseNumber: string, addition?: string) {
    return new Promise<CustomerAppointmentResponse>((resolve, reject) => {
      return this.post<CustomerAppointmentResponse>('/customerappointment', {
        postalCode: postCode,
        number: houseNumber,
        // If addition is falsey pass it as undefined, since empty strings are not allowed
        addition: addition || undefined
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        return reject(APPOINTMENT_FAILURE_API_ERROR)
      })
    })
  }

  setCustomerAppointmentProblems(appointmentId: string, problems: object) {
    return new Promise<CustomerAppointmentResponse>((resolve, reject) => {
      return this.put<CustomerAppointmentResponse>(`/customerappointment/${appointmentId}/problem`,
        {
          codes: problems
        }
      ).then(res => {
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        return reject(APPOINTMENT_FAILURE_API_ERROR)
      })
    })
  }

  setCustomerAppointmentProblemAreas(appointmentId: string, problemAreas: object, remarks: string | null) {
    return new Promise<CustomerAppointmentResponse>((resolve, reject) => {
      return this.put<CustomerAppointmentResponse>(`/customerappointment/${appointmentId}/object`,
        {
          codes: problemAreas,
          remarks
        }
      ).then(res => {
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        return reject(APPOINTMENT_FAILURE_API_ERROR)
      })
    })
  }

  getAppointmentProposals(appointmentId: string, fromDate?: string) {
    return new Promise<[AppointmentProposal]>((resolve, reject) => {
      return this.get<[AppointmentProposal]>(`/appointmentproposal/${appointmentId}`, fromDate ? { appointmentDate: fromDate } : {}).then(res => {
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        return reject(APPOINTMENT_FAILURE_API_ERROR)
      })
    })
  }

  setCustomerAppointmentProposal(appointmentId: string, appointmentProposal: object) {
    return new Promise<CustomerAppointmentResponse>((resolve, reject) => {
      return this.put<CustomerAppointmentResponse>(`/customerappointment/${appointmentId}/proposal`,
        appointmentProposal
      ).then(res => {
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        return reject(APPOINTMENT_FAILURE_API_ERROR)
      })
    })
  }

  setCustomerAppointmentContactDetails(appointmentId: string, contactDetails: object) {
    return new Promise<CustomerAppointmentResponse>((resolve, reject) => {
      return this.put<CustomerAppointmentResponse>(`/customerappointment/${appointmentId}/contact`,
        contactDetails
      ).then(res => {
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        return reject(APPOINTMENT_FAILURE_API_ERROR)
      })
    })
  }

  confirmCustomerAppointment(appointmentId: string, appointmentProposal: object) {
    return new Promise<CustomerAppointmentResponse>((resolve, reject) => {
      return this.put<CustomerAppointmentResponse>(`/customerappointment/${appointmentId}`,
        appointmentProposal
      ).then(res => {
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        return reject(APPOINTMENT_FAILURE_API_ERROR)
      })
    })
  }

  getCustomerAppointmentStatus(postCode: string, houseNumber: string, addition?: string) {
    return new Promise<CustomerAppointmentStatusResponse>((resolve, reject) => {
      return this.get<CustomerAppointmentStatusResponse>('/customerappointment/state', {
        postalCode: postCode,
        number: houseNumber,
        addition
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        return reject(APPOINTMENT_FAILURE_API_ERROR)
      })
    })
  }

  getCustomerAppointment(appointmentId: string, postCode: string, houseNumber: string, addition?: string) {
    return new Promise<CustomerAppointmentResponse>((resolve, reject) => {
      return this.get<CustomerAppointmentResponse>(`/customerappointment/${appointmentId}`, {
        postalCode: postCode,
        number: houseNumber,
        addition
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        return reject(APPOINTMENT_FAILURE_API_ERROR)
      })
    })
  }

  cancelCustomerAppointment(appointmentId: string, dilapidatedReason: string, dilapidatedReasonRemarks?: string) {
    return this.delete<never>(`/customerappointment/${appointmentId}`,
      {
        customerAppointmentId: appointmentId,
        dilapidatedReason: dilapidatedReason,
        dilapidatedReasonRemarks: dilapidatedReasonRemarks
      }).then(res => {
      return res.data
    }).catch(err => {
      console.error(err)
      throw err
    })
  }
}
