import { Commit } from 'vuex'

import ApiClient from '@/api'
import { CustomerAppointmentResponse } from '@/api/types'
import { State as RootState } from '@/store/index'

export interface State {
  customerAppointment: CustomerAppointmentResponse | null;
  customerAppointmentFailure: boolean | null;
  confirmCustomerAppointmentFailure: boolean | null;
}

export default {
  namespaced: true,
  state: {
    customerAppointment: null,
    customerAppointmentFailure: null,
    confirmCustomerAppointmentFailure: null
  },
  mutations: {
    updateCustomerAppointment(state: State, value: CustomerAppointmentResponse | null) {
      state.customerAppointment = value
    },
    updateCustomerAppointmentFailure(state: State, value: boolean) {
      state.customerAppointmentFailure = value
    },
    updateConfirmCustomerAppointmentFailure(state: State, value: boolean) {
      state.confirmCustomerAppointmentFailure = value
    }
  },
  actions: {
    unsetCustomerAppointment({ commit }: { commit: Commit }) {
      commit('updateCustomerAppointment', null)
      commit('updateCustomerAppointmentFailure', false)
    },
    createCustomerAppointment({
      commit,
      rootState
    }: { commit: Commit; rootState: RootState }) {
      commit('updateCustomerAppointment', null)
      commit('updateCustomerAppointmentFailure', null)

      const address = rootState.address

      if (address.postCode && address.houseNumber) {
        ApiClient.createCustomerAppointment(address.postCode, address.houseNumber, address.addition).then(response => {
          commit('updateCustomerAppointment', response)
          commit('updateCustomerAppointmentFailure', false)
        }).catch(() => {
          commit('updateCustomerAppointment', null)
          commit('updateCustomerAppointmentFailure', true)
        })
      } else {
        commit('updateCustomerAppointment', null)
        commit('updateCustomerAppointmentFailure', true)
      }
    },
    confirmCustomerAppointment({
      commit,
      state,
      rootState
    }: { commit: Commit; state: State; rootState: RootState }) {
      commit('updateConfirmCustomerAppointmentFailure', null)

      let proposal = rootState.proposals.selectedProposal
      if (!proposal) {
        const currentDate = new Date()
        const proposalDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()))
        const proposalDateString = proposalDate.toISOString()

        proposal = {
          id: 'callback',
          employeeId: null,
          date: proposalDateString,
          from: proposalDateString,
          till: proposalDateString
        }
      }

      const appointmentId = state.customerAppointment?.customerAppointmentId
      if (appointmentId) {
        ApiClient.confirmCustomerAppointment(appointmentId, proposal).then(() => {
          commit('updateConfirmCustomerAppointmentFailure', false)
        }).catch(() => {
          commit('updateConfirmCustomerAppointmentFailure', true)
        })
      } else {
        commit('updateConfirmCustomerAppointmentFailure', true)
      }
    },
    restoreFromAppointmentStatus({
      commit,
      rootState
    }: { commit: Commit; rootState: RootState }) {
      if (rootState.appointmentStatus.customerAppointment) {
        commit('updateCustomerAppointment', rootState.appointmentStatus.customerAppointment)
      }
    },
    cancelCustomerAppointment({ state }: { state: State }, payload: { reason: string; remark: string | null }) {
      const customerAppointmentId = state.customerAppointment?.customerAppointmentId ?? null

      if (!customerAppointmentId) {
        throw new Error('customerAppointmentId does not exist, and we cant cancel without this')
      }

      return ApiClient.cancelCustomerAppointment(customerAppointmentId, payload.reason, payload.remark ?? 'Geen reden opgegeven')
    }
  }
}
