





















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { Step } from '@/steps/index'
import Select from '@/components/generic/Select.vue'
import Loading from '@/components/generic/Loading.vue'
import { displayDate, displayDateWithoutYear, displayTime } from '@/utils'
import { AppointmentProposal, CustomerAppointmentStatusResponse } from '@/api/types'
import { maxNumberOfDatesToAllowFetchMoreProposals, State as ProposalState } from '@/store/proposals'
import { arrayHasOne } from '@/utils/validators'
import { ERROR_TOAST } from '@/utils/toasts'
import Checkbox from '@/components/generic/Checkbox.vue'
import { State as AppointmentState } from '@/store/appointment'

const proposalsNamespace = namespace('proposals')
const appointmentState = namespace('appointment')
const appointmentStatusState = namespace('appointmentStatus')

@Component({
  name: 'Step5',
  components: {
    Checkbox,
    Select,
    Loading
  },
  validations: {
    selectedDate: {
      arrayHasOne
    },
    selectedTime: {
      arrayHasOne
    }
  }
})
export default class Step5 extends Vue implements Step {
  @proposalsNamespace.State(state => state.getAppointmentProposalsFailure) getProposalsFailure!: ProposalState['getAppointmentProposalsFailure']
  @proposalsNamespace.State(state => state.setAppointmentProposalFailure) setProposalFailure!: ProposalState['setAppointmentProposalFailure']
  @appointmentState.State(state => state.confirmCustomerAppointmentFailure) confirmCustomerAppointmentFailure!: boolean | null
  @appointmentStatusState.State(state => state.customerAppointmentStatus) customerAppointmentStatus!: CustomerAppointmentStatusResponse | null

  selectedDate: string[] = []
  loading = false
  loadingMore = false
  noProposalsAvailable = false

  mounted() {
    this.validate()
    if (
      this.$store.state.proposals.proposals.length === 0 ||
      this.$store.state.proposals.appointmentId !== this.$store.state.appointment.customerAppointment?.customerAppointmentId
    ) {
      this.loading = true
      this.noProposalsAvailable = false
      this.selectedDate = []

      this.$store.dispatch('proposals/fetchAppointmentProposals').finally(() => {
        if (this.$store.state.proposals.proposals.length === 0) {
          this.noProposalsAvailable = true
          this.$emit('validated', this.valid)
        }
        this.loading = false
      })
    } else if (this.$store.state.proposals.selectedProposal) {
      const proposal = { ...this.$store.state.proposals.selectedProposal }
      this.selectedDate = [proposal.date]
      this.$nextTick(() => {
        this.$store.commit('proposals/updateSelectedProposal', proposal)
      })
    }
  }

  get isEditingAppointment() {
    return this.$store.getters.getEditingAppointment
  }

  get selectedTime() {
    if (this.$store.state.proposals.selectedProposal) {
      return [this.$store.state.proposals.selectedProposal.id]
    }
    return []
  }

  set selectedTime(value) {
    if (value.length === 0) {
      this.$store.commit('proposals/updateSelectedProposal', null)
    }
    const selectedAppointmentProposal = this.possibleAppointmentTimes.find(item => item.id === value[0])
    if (selectedAppointmentProposal) {
      this.$store.commit('proposals/updateSelectedProposal', selectedAppointmentProposal)
    }
  }

  get possibleAppointmentDates(): [string] {
    return this.$store.getters['proposals/getPossibleAppointmentDates']
  }

  get possibleAppointmentTimes(): AppointmentProposal[] {
    if (!(this.selectedDate.length)) {
      return []
    }
    return this.$store.getters['proposals/getPossibleAppointmentsOnDate'](this.selectedDate[0])
  }

  get appointmentDateOptions() {
    return this.possibleAppointmentDates.map((item: string) => {
      return {
        value: item,
        name: displayDate(item),
        icon: null
      }
    })
  }

  get appointmentTimeOptions() {
    return this.possibleAppointmentTimes.map((item: AppointmentProposal) => {
      return {
        value: item.id,
        name: this.displayTime(item.from) + ' - ' + this.displayTime(item.till),
        icon: null,
        label: (item.surcharge ?? 0) > 0 ? item.surcharge + '% toeslag' : null
      }
    })
  }

  get filterSurchargeProposals() {
    return this.$store.state.proposals.filterSurchargeProposals
  }

  set filterSurchargeProposals(value) {
    this.$store.commit('proposals/updateFilterSurchargeProposals', value)
  }

  get hasAnySurcharge() {
    return this.$store.getters['proposals/getDateHasSurcharges'](this.selectedDate[0])
  }

  get valid() {
    return !this.$v.$invalid || this.noProposalsAvailable
  }

  @Watch('confirmCustomerAppointmentFailure')
  onConfirmCustomerAppointmentFailure(newValue: AppointmentState['confirmCustomerAppointmentFailure']) {
    if (newValue === true) {
      this.$emit('submitted', false)
    } else if (newValue === false) {
      const postCode = this.$store.state.address.postCode ?? ''
      const houseNumber = this.$store.state.address.houseNumber ?? ''
      const addition = this.$store.state.address.addition ?? ''

      window.location.href = '/afspraakstatus/' + postCode + '/' + houseNumber + '/' + addition
    }
  }

  @Watch('getProposalsFailure')
  onGetProposalsFailureChange(newValue: ProposalState['getAppointmentProposalsFailure']) {
    if (newValue === true) {
      this.$bvToast.toast('We konden geen geschikte momenten voor een afspraak vinden', ERROR_TOAST)
    }
  }

  onSelectedDateInput() {
    this.validate()
    this.$nextTick(this.scrollToTimeSelection)
  }

  onSelectedTimeInput() {
    this.validate()
  }

  @Watch('setProposalFailure')
  onSetProposalFailureChange(newValue: ProposalState['setAppointmentProposalFailure']) {
    if (newValue === true) {
      this.$emit('submitted', false)
    } else if (newValue === false) {
      this.$emit('submitted', true)
    }
  }

  validate() {
    this.$v.$touch()
    this.$emit('validated', this.valid)
  }

  submit() {
    if (this.isEditingAppointment) {
      this.$store.dispatch('appointment/confirmCustomerAppointment')
    } else {
      if (this.$store.state.proposals.selectedProposal) {
        this.$store.dispatch('proposals/setAppointmentProposal')
      } else if (this.noProposalsAvailable) {
        this.$emit('submitted', true)
      }
    }
  }

  displayDate(date: string) {
    return displayDateWithoutYear(date)
  }

  displayTime(date: string) {
    return displayTime(date)
  }

  scrollToTimeSelection() {
    const selectedTimeArea = this.$refs.time_selection
    if (selectedTimeArea) {
      this.$smoothScroll({
        scrollTo: selectedTimeArea as Element
      })
    }
  }

  get canDisplayMoreDates() {
    return this.possibleAppointmentDates.length > 0 && this.possibleAppointmentDates.length < maxNumberOfDatesToAllowFetchMoreProposals
  }

  fetchMoreDates() {
    this.loadingMore = true
    this.$store.dispatch('proposals/fetchMoreProposals').finally(() => {
      this.loadingMore = false
    })
  }
}
