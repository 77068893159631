
















































import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { debounce as Debounce } from 'decko'
import { namespace } from 'vuex-class'
import Multiselect from 'vue-multiselect'

import { AddressContract, AddressResponse, CustomerAppointmentResponse } from '@/api/types'
import TextInput from '@/components/generic/TextInput.vue'
import Loading from '@/components/generic/Loading.vue'
import { isPostcode } from '@/utils/validators'
import CallToAction from '@/components/generic/buttons/CallToAction.vue'
import { Step } from '@/steps/index'
import Select from '@/components/generic/Select.vue'
import TextInputAutoComplete from '@/components/generic/TextInputAutoComplete.vue'

const appointmentNamespace = namespace('appointment')
const addressNamespace = namespace('address')

@Component({
  name: 'Step1',
  components: {
    TextInputAutoComplete,
    Select,
    CallToAction,
    TextInput,
    Loading,
    Multiselect
  },
  validations: {
    postCode: {
      required,
      isPostcode
    },
    houseNumber: { required }
  }
})

export default class Step1 extends Vue implements Step {
  @appointmentNamespace.State(state => state.customerAppointment) customerAppointment!: CustomerAppointmentResponse | null
  @appointmentNamespace.State(state => state.customerAppointmentFailure) customerAppointmentFailure!: boolean | null
  @addressNamespace.State(state => state.resolvedAdditions) additionSuggestions!: string[]
  @addressNamespace.State(state => state.contractData) contractData!: AddressContract | null

  loading = false
  selected = null

  mounted() {
    // We get the suggestion on mount, since we may be navigating back to this prop with a valid state
    this.onAddressChange()
    this.onAddressSuggestionChange()

    const postCode = this.$refs.postCode as TextInput
    postCode.focus()
  }

  get postCode() {
    return this.$store.state.address.postCode
  }

  set postCode(value) {
    this.$store.commit('address/updatePostcode', value)
  }

  get houseNumber() {
    return this.$store.state.address.houseNumber
  }

  set houseNumber(value) {
    this.$store.commit('address/updateHousenumber', value)
  }

  get addition() {
    return this.$store.state.address.addition
  }

  set addition(value) {
    this.$store.commit('address/updateAddition', value)
  }

  get addressSuggestion(): AddressResponse | null {
    return this.$store.state.address.resolvedAddress
  }

  get addressSuggestionFailure(): string | null {
    return this.$store.state.address.resolvedAddressFailure
  }

  get addressSuggestionLine() {
    if (this.addressSuggestion === null) {
      return ''
    }
    let result = this.addressSuggestion?.street + ' ' + this.addressSuggestion?.number
    if (this.addressSuggestion?.addition) {
      result += ' ' + this.addressSuggestion.addition
    }
    result += ', ' + this.addressSuggestion?.city
    return result
  }

  get valid() {
    return !!(!this.$v.$invalid && this.addressSuggestion)
  }

  @Watch('postCode')
  @Watch('houseNumber')
  @Watch('addition')
  onAddressChange() {
    // validate if we need to do a request, this resolves emptying the state when switching back to this step. and is also a performance win
    if (this.addressSuggestion?.postalCode.toLowerCase() === this.postCode?.toLowerCase() &&
      this.addressSuggestion?.number.toString().toLowerCase() === this.houseNumber?.toLowerCase() &&
      // Ugly line, since addressSuggestion is probably null or undefined we need to convert it to empty string to get a good comparison (since the input is empty string)
      (this.addressSuggestion?.addition?.toLowerCase() ?? '') === (this.addition?.toLowerCase() ?? '')) {
      // add check if there are multiple additions available
      return
    }

    this.$store.dispatch('address/unsetResolvedAddress')

    if (this.$v.$invalid || !this.postCode || !this.houseNumber) {
      // don't do anything, some variables not set
      return
    }

    this.fetchResolvedAdditions()
    this.fetchResolvedAddress()
  }

  @Watch('postCode')
  @Watch('houseNumber')
  @Watch('addition')
  @Watch('customerAppointment')
  async onAddressDataChangeUpdateGA() {
    if (!(this.postCode || this.houseNumber || this.addition)) {
      return
    }
    let contractType = ''
    let contractTypeName = ''
    if (this.postCode && this.houseNumber) {
      await this.$store.dispatch('address/fetchContractInformation')
      contractType = this.contractData?.contractType ?? ''
      contractTypeName = this.contractData?.contractTypeName ?? ''
    }
    window.dataLayer.push({
      event: 'postcodeCheck',
      contractType,
      contractTypeName,
      postalCode: this.postCode,
      postalNumber: this.houseNumber
    })
  }

  @Watch('addressSuggestion')
  onAddressSuggestionChange() {
    this.$emit('validated', this.valid)
  }

  @Watch('customerAppointment')
  @Watch('customerAppointmentFailure')
  onCustomerAppointmentChange() {
    if (!this.customerAppointmentFailure && this.customerAppointment) {
      this.$emit('submitted', true)
    } else if (this.customerAppointmentFailure) {
      this.$emit('submitted', false)
    }
  }

  @Debounce(1000)
  fetchResolvedAddress() {
    this.loading = true
    this.$store.dispatch('address/fetchResolvedAddress').finally(() => {
      this.loading = false
    })
  }

  @Debounce(1000)
  fetchResolvedAdditions() {
    this.loading = true
    this.$store.dispatch('address/fetchResolvedAdditions').finally(() => {
      this.loading = false
    })
  }

  validate() {
    this.$v.$touch()
    this.$emit('validated', this.valid)
  }

  submit() {
    this.$store.dispatch('appointment/createCustomerAppointment')
  }
}
