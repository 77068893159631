import Vue from 'vue'
import Vuex from 'vuex'

import ApiClient from '@/api'
import wizard, { State as WizardState } from '@/store/wizard'
import address, { State as AddressState } from '@/store/address'
import appointment, { State as AppointmentState } from '@/store/appointment'
import appointmentStatus, { State as AppointmentStatusState } from '@/store/appointmentStatus'
import problems, { State as ProblemsState } from '@/store/problems'
import problemAreas, { State as ProblemAreasState } from '@/store/problemAreas'
import proposals, { State as ProposalsState } from '@/store/proposals'
import contactDetails, { State as ContactState } from '@/store/contactDetails'
import price, { State as PriceState } from '@/store/price'
import { ContactResponse } from '@/api/types'

Vue.use(Vuex)

export interface State {
  generalPhoneNumber: string;
  wizard: WizardState;
  address: AddressState;
  appointment: AppointmentState;
  appointmentStatus: AppointmentStatusState;
  problems: ProblemsState;
  problemArea: ProblemAreasState;
  proposals: ProposalsState;
  contactDetails: ContactState;
  price: PriceState;
}

export interface Getters {
  getAppointmentId: string | null;
  getGeneralPhoneNumber: string;
  getPhoneNumber: string;
  getEditingAppointment: boolean;
}

export default new Vuex.Store({
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore,@typescript-eslint/ban-ts-comment
  // @ts-ignore
  state: {
    generalPhoneNumber: ''
  },
  mutations: {
    updateGeneralPhoneNumber(state: { generalPhoneNumber: string }, value: string) {
      state.generalPhoneNumber = value
    }
  },
  getters: {
    getAppointmentId: (state: State) => {
      return state.appointment.customerAppointment?.customerAppointmentId ?? null
    },
    getGeneralPhoneNumber: (state: State) => {
      return state.generalPhoneNumber ?? ''
    },
    getPhoneNumber: (state: State) => {
      let phoneNumber = state.appointment.customerAppointment?.branchOffice?.phoneNumber ?? state.generalPhoneNumber

      let contactTypes: ContactResponse[] = []
      contactTypes = contactTypes.concat(state.appointmentStatus.customerAppointmentStatus?.branchOffice?.contactTypes ?? [])
      contactTypes = contactTypes.concat(state.appointment.customerAppointment?.branchOffice?.contactTypes ?? [])

      contactTypes.forEach(contactType => {
        if (contactType.name === 'Planning' && contactType.type === 'Telefoon') {
          phoneNumber = contactType.value
        }
      })

      return phoneNumber
    },
    getEditingAppointment: (state: State) => {
      return !!state.appointmentStatus.customerAppointmentStatus
    }
  },
  actions: {
    fetchGeneralPhoneNumber({ commit }) {
      ApiClient.getTelephoneInformation().then(response => {
        commit('updateGeneralPhoneNumber', response.data.value)
      }).catch(error => {
        console.error(error)
      })
    }
  },
  modules: {
    wizard: wizard,
    address: address,
    appointment: appointment,
    appointmentStatus: appointmentStatus,
    problems: problems,
    problemAreas: problemAreas,
    proposals: proposals,
    contactDetails: contactDetails,
    price: price
  }
})
