import { Commit } from 'vuex'

import { State as RootState } from '@/store/index'
import ApiClient from '@/api'

export interface State {
  gender: 'Male' | 'Female' | null;
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  statusUpdatesSms: boolean | null;
  remarks: string | null;
  usedDisintegrant: boolean | null;
  acceptedConditions: boolean | null;
  setContactDetailsFailure: boolean | null;
}

export default {
  namespaced: true,
  state: {
    gender: null,
    name: null,
    email: null,
    phoneNumber: null,
    statusUpdatesSms: false,
    remarks: null,
    usedDisintegrant: false,
    acceptedConditions: false,
    setContactDetailsFailure: null
  },
  mutations: {
    updateGender(state: State, value: State['gender']) {
      state.gender = value
    },
    updateName(state: State, value: State['name']) {
      state.name = value
    },
    updateEmail(state: State, value: State['email']) {
      state.email = value
    },
    updatePhoneNumber(state: State, value: State['phoneNumber']) {
      state.phoneNumber = value
    },
    updateStatusUpdatesSms(state: State, value: State['statusUpdatesSms']) {
      state.statusUpdatesSms = value
    },
    updateRemarks(state: State, value: State['remarks']) {
      state.remarks = value
    },
    updateUsedDisintegrant(state: State, value: State['usedDisintegrant']) {
      state.usedDisintegrant = value
    },
    updateAcceptedConditions(state: State, value: State['acceptedConditions']) {
      state.acceptedConditions = value
    },
    updateSetContactDetailsFailure(state: State, value: State['setContactDetailsFailure']) {
      state.setContactDetailsFailure = value
    }
  },
  getters: {
    salutation: (state: State) => {
      if (state.gender === 'Male') {
        return 0
      } else if (state.gender === 'Female') {
        return 1
      }
      return 2
    }
  },
  actions: {
    setContactDetails(
      {
        commit,
        state,
        getters,
        rootState
      }: {
        commit: Commit;
        state: State;
        getters: { salutation: number };
        rootState: RootState;
      }) {
      commit('updateSetContactDetailsFailure', null)

      const appointmentId = rootState.appointment.customerAppointment?.customerAppointmentId

      if (appointmentId) {
        ApiClient.setCustomerAppointmentContactDetails(appointmentId, {
          acceptedConditions: state.acceptedConditions,
          gender: state.gender ?? 'Onbekend',
          salutation: getters.salutation,
          name: state.name,
          emailAddress: state.email,
          mobileNumber: state.phoneNumber,
          phoneNumber: state.phoneNumber,
          statusUpdatesSms: state.statusUpdatesSms,
          statusUpdatesEmail: !!state.email,
          remarks: state.remarks,
          usedDisintegrant: state.usedDisintegrant
        }).then(() => {
          commit('updateSetContactDetailsFailure', false)
        }).catch(() => {
          commit('updateSetContactDetailsFailure', true)
        })
      } else {
        commit('updateSetContactDetailsFailure', true)
      }
    },
    restoreFromAppointmentStatus({ commit, rootState }: { commit: Commit; rootState: RootState }) {
      if (rootState.appointmentStatus.customerAppointment) {
        const contact = rootState.appointmentStatus.customerAppointment.contact

        let gender = null
        if (contact.salutation === '0') {
          gender = 'Male'
        } else if (contact.salutation === '1') {
          gender = 'Female'
        }

        commit('updateAcceptedConditions', contact.acceptedConditions)
        commit('updateGender', gender)
        commit('updateName', contact.name)
        commit('updateEmail', contact.emailAddress)
        commit('updatePhoneNumber', contact.phoneNumber)
        commit('updateStatusUpdatesSms', contact.statusUpdatesSms)
        commit('updateRemarks', contact.remarks)
        commit('updateUsedDisintegrant', contact.usedDisintegrant)
      }
    }
  }
}
