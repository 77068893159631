import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueTippy, { TippyComponent } from 'vue-tippy'
import '@babel/polyfill'
import 'mutationobserver-shim'
import VueSmoothScroll from 'v-smooth-scroll'
import { Component } from 'vue-property-decorator'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import App from './App.vue'
import router from './router'
import store from './store'

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginVue()],
  releaseStage: process.env.VUE_APP_BUGSNAG_STAGE
})

Component.registerHooks(['validations'])

Vue.use(Vuelidate)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  flipDuration: 0
})
Vue.use(VueSmoothScroll)
Vue.component('tippy', TippyComponent)

Vue.config.productionTip = false

// GTM, setup dataLayer.
window.dataLayer = window.dataLayer || []

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
