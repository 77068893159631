import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Wizard from '@/views/Wizard.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'wizard',
    component: Wizard
  },
  {
    path: '/adres/:postalCode?/:houseNumber?/:addition?',
    name: 'address',
    component: () => import('../views/Address.vue')
  },
  {
    path: '/bedankt',
    name: 'success',
    component: () => import('../views/Success.vue')
  },
  {
    path: '/bedankt-datum-plannen',
    name: 'success-plan-date',
    component: () => import('../views/Success.vue'),
    props: {
      isDateless: true
    }
  },
  {
    path: '/afspraakstatus',
    name: 'appointmentCheckStatus',
    component: () => import(/* webpackChunkName: "status" */ '../views/AppointmentCheckStatus.vue')
  },
  {
    path: '/afspraakstatus/:postalCode?/:houseNumber?/:addition?',
    name: 'appointmentStatus',
    component: () => import(/* webpackChunkName: "status" */ '../views/AppointmentStatus.vue')
  },
  {
    path: '/geannuleerd',
    name: 'cancelled',
    component: () => import(/* webpackChunkName: "status" */ '../views/CancelSuccess.vue')
  },
  {
    path: '*',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
